import * as React from 'react'

export interface UseDisclosureProps {
  defaultIsOpen?: boolean
  onClose?(): void
  onOpen?(): void
}

export function useDisclosure(props: UseDisclosureProps = {}) {
  const { onClose: onCloseProp, onOpen: onOpenProp } = props

  const [isOpenState, setIsOpen] = React.useState(props.defaultIsOpen || false)

  const onClose = React.useCallback(() => {
    setIsOpen(false)
    onCloseProp?.()
  }, [onCloseProp])

  const onOpen = React.useCallback(() => {
    setIsOpen(true)
    onOpenProp?.()
  }, [onOpenProp])

  const onToggle = React.useCallback(() => {
    const action = isOpenState ? onClose : onOpen
    action()
  }, [isOpenState, onOpen, onClose])

  return {
    isOpen: isOpenState,
    onOpen,
    onClose,
    onToggle,
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
