import * as React from 'react'

export const DefaultLogo: React.FC = () => (
  <SpecificLogo children={'Ferretería'} />
)

export const SpecificLogo: React.FC = ({ children }) => (
  <h1 className={'font-medium text-blue-600 text-sm md:text-2xl'}>
    {children}{' '}
    <small className={'block md:inline-block text-black text-xs'}>
      Domicilios 24/7
    </small>
  </h1>
)
