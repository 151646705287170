import * as React from 'react'

export interface VisibilityProps {
  flex?: boolean
}

export interface OnlyMobileProps extends VisibilityProps {}

export const OnlyMobile: React.FC<OnlyMobileProps> = ({
  flex = true,
  ...props
}) => {
  return (
    <div
      className={`${
        flex ? 'inline-flex' : 'inline-block'
      } md:hidden align-middle`}
      {...props}
    />
  )
}

export interface NotMobileProps extends VisibilityProps {}

export const NotMobile: React.FC<NotMobileProps> = ({ flex, ...props }) => {
  return (
    <div
      className={`hidden align-middle ${
        flex ? 'md:inline-flex' : 'md:inline-block'
      }`}
      {...props}
    />
  )
}
