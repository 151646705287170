import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'

export interface HeaderLinkProps {
  href: string
  active?: boolean
  spa?: boolean
  onClick?: () => void
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  spa,
  href,
  active,
  onClick,
  children,
  ...props
}) => {
  if (spa) {
    return (
      <GatsbyLink
        to={href}
        className={`btn btn-ghost link ${active ? 'act-link' : ''}`}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={href} className={'btn btn-ghost link'} onClick={onClick}>
      {children}
    </a>
  )
}
