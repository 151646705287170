import * as React from 'react'
import { Link } from 'gatsby'
import { FaBars } from 'react-icons/fa'
import { NotMobile, OnlyMobile } from './visibility'
import { CallButton } from './call-button'
import { IconButton } from './icon-button'
import { useDisclosure } from './disclosure'
import { NavBar } from './nav-bar'
import { HeaderLink } from './link'

export interface VisiblePage {
  home?: boolean
  locksmith?: boolean
  electrician?: boolean
  plumber?: boolean
}

export interface HeaderProps {
  isOpen: boolean
  onToggle?: () => void
  onClose?: () => void
  logo: React.ReactNode
  visible: VisiblePage
}

export const Header: React.FC<HeaderProps> = ({
  isOpen,
  onToggle,
  onClose,
  visible,
  logo,
  ...props
}) => {
  return (
    <header
      className={
        'shadow-md flex py-5 px-3 z-30 w-full fixed inset-x top-0 bg-white justify-between'
      }
    >
      <Link to={'/'}>{logo}</Link>

      <TelButton action={'mobile-header'} onlyMobile />

      <OnlyMobile>
        <div className={'ml-1.5 z-10'}>
          <IconButton
            icon={<FaBars />}
            label={'Abrir menú'}
            aria-haspopup="true"
            onClick={() => onToggle?.()}
          />
        </div>
      </OnlyMobile>

      <HeaderMenu
        visible={visible}
        isOpen={isOpen}
        onClick={() => onClose?.()}
      />
    </header>
  )
}

const TelButton: React.FC<{ onlyMobile: boolean; action: string }> = ({
  onlyMobile,
  action,
}) => {
  const Wrapper = onlyMobile ? OnlyMobile : NotMobile

  return (
    <Wrapper>
      <CallButton action={action}>{onlyMobile ? '' : 'Llamar '}</CallButton>
    </Wrapper>
  )
}

export interface OverlayProps {
  isOpen: boolean
  onClose: () => void
}

export const Overlay: React.FC<OverlayProps> = ({ isOpen, onClose }) => {
  return (
    <div
      className={`inset-x top-0 w-full h-full z-20 fixed bg-black bg-opacity-50 ${
        isOpen ? 'block' : 'hidden'
      }`}
      onClick={onClose}
    />
  )
}

export interface HeaderWithOverlayProps
  extends Pick<HeaderProps, 'logo' | 'visible'> {}

export const HeaderWithOverlay: React.FC<HeaderWithOverlayProps> = ({
  logo,
  visible,
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <Header
        isOpen={isOpen}
        logo={logo}
        onToggle={onToggle}
        onClose={onClose}
        visible={visible}
      />
      <Overlay isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export interface HeaderMenuProps {
  visible: VisiblePage
  isOpen: boolean
  onClick: () => void
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({
  visible,
  isOpen,
  onClick,
}) => {
  return (
    <NavBar aria-label={'Principal'} isOpen={isOpen}>
      <HeaderLink spa href={'/'} active={visible.home}>
        Ferretería
      </HeaderLink>
      <HeaderLink spa href={'/cerrajeria/'} active={visible.locksmith}>
        Cerrajería
      </HeaderLink>
      <HeaderLink spa href={'/electricistas/'} active={visible.electrician}>
        Electricistas
      </HeaderLink>
      <HeaderLink spa href={'/plomeria/'} active={visible.plumber}>
        Plomería
      </HeaderLink>
      <HeaderLink href={'#servicios'} onClick={onClick}>
        Servicios
      </HeaderLink>
      <HeaderLink href={'#cobertura'} onClick={onClick}>
        Cobertura
      </HeaderLink>
      <HeaderLink href={'#contacto'} onClick={onClick}>
        Contacto
      </HeaderLink>
      <TelButton action={'desktop-header'} onlyMobile={false} />
    </NavBar>
  )
}
