import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const useLocksmithImages = () => {
  return useStaticQuery(graphql`
    query {
      llavesConChip: file(
        relativePath: { eq: "cerrajeria/llaves-con-chip.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      duplicacionDeLlaves: file(
        relativePath: { eq: "cerrajeria/duplicacion-de-llaves.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aperturaDeVehiculos: file(
        relativePath: { eq: "cerrajeria/apertura-de-vehiculos.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aperturaDeCajasFuertes: file(
        relativePath: { eq: "cerrajeria/apertura-de-cajas-fuertes.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cambioDeGuardas: file(
        relativePath: { eq: "cerrajeria/cambio-de-guardas.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cambioDeGuardasConTaladro: file(
        relativePath: { eq: "cerrajeria/cambio-de-guardas-taladro.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionDePuertasDeSeguridad: file(
        relativePath: {
          eq: "cerrajeria/instalacion-de-puertas-de-seguridad.jpeg"
        }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      brazosHidraulicos: file(
        relativePath: { eq: "cerrajeria/brazos-hidraulicos.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ojosMagicos: file(relativePath: { eq: "cerrajeria/ojos-magicos.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cambioDeCerraduras: file(
        relativePath: { eq: "cerrajeria/cambio-de-cerraduras.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aperturaVehiculosAltaGama: file(
        relativePath: { eq: "cerrajeria/apertura-vehiculos-alta-gama.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aperturaVehiculosAltaBaja: file(
        relativePath: { eq: "cerrajeria/apertura-vehiculos-baja-gama.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cambioDeChapasSencillas: file(
        relativePath: { eq: "cerrajeria/cambio-de-cerraduras-sencillas.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aperturaPuertasSeguridad: file(
        relativePath: { eq: "cerrajeria/apertura-puertas-de-seguridad.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mantenimientoCajasFuertes: file(
        relativePath: { eq: "cerrajeria/mantenimiento-cajas-fuertes.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionesCajasFuertes: file(
        relativePath: { eq: "cerrajeria/instalacion-cajas-fuertes.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  // : file(relativePath: { eq: "cerrajeria/.jpg" }) { childImageSharp { fluid(quality: 70, maxWidth: 400) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
}

export const LocksmithImage = ({ reference, ...props }) => {
  const data = useLocksmithImages()

  return <Img fluid={data[reference].childImageSharp.fluid} {...props} />
}
