import * as React from 'react'
import { HeaderWithOverlay, VisiblePage } from './header'
import { Footer } from './footer'

import '../styles/app.css'

export interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  theme?: any
  visible: VisiblePage
  logo: React.ReactNode
}

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ theme, logo, children, visible, ...props }, ref) => (
    <div ref={ref} {...props}>
      <HeaderWithOverlay logo={logo} visible={visible} />
      <main style={{ marginTop: '81px' }}>{children}</main>
      <Footer />
    </div>
  ),
)
