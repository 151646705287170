import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useLocksmithImages } from './locksmith-image'
import { useElectricianImages } from './electrician-image'
import { usePlumberImages } from './plumber-image'

const useDefaultImages = () => {
  return useStaticQuery(graphql`
    query {
      keysWithChip: file(relativePath: { eq: "replace-car-key.jpg" }) {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      unlockCar: file(relativePath: { eq: "apertura-de-vehiculos.jpg" }) {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      homeSlider: file(relativePath: { eq: "home-slider.jpg" }) {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      homeSectionTwo: file(relativePath: { eq: "slider-home-2.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      woman: file(relativePath: { eq: "woman.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      man: file(relativePath: { eq: "man.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      plumber: file(relativePath: { eq: "plumber.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      lockSmithSlider: file(relativePath: { eq: "slider.jpg" }) {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      plumberSlider: file(relativePath: { eq: "slider-plumber.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      plumberDesign: file(relativePath: { eq: "slider-plumber-2.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      carKey: file(relativePath: { eq: "car-key.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      fastDelivery: file(relativePath: { eq: "fast-delivery.jpeg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      electricianSlider: file(relativePath: { eq: "slider-2.jpg" }) {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      fastCar: file(relativePath: { eq: "fast-car.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 520) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      logo: file(relativePath: { eq: "logo_small.png" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
}

export const useImages = () => {
  const locksmithData = useLocksmithImages()
  const defaultData = useDefaultImages()
  const electricianData = useElectricianImages()
  const plumberData = usePlumberImages()

  return React.useMemo(
    () => ({
      ...locksmithData,
      ...defaultData,
      ...electricianData,
      ...plumberData,
    }),
    [locksmithData, defaultData, electricianData, plumberData],
  )
}

export const MainImage = ({ reference, loading = 'lazy', ...props }) => {
  const data = useImages()

  return (
    <Img
      loading={loading}
      fluid={data[reference].childImageSharp.fluid}
      {...props}
    />
  )
}
