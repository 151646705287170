import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const useElectricianImages = () => {
  return useStaticQuery(graphql`
    query {
      instalacionesElectricas: file(
        relativePath: { eq: "electricidad/diseno-instalaciones-electricas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sistemaPuestaTierra: file(
        relativePath: { eq: "electricidad/diseno-puesta-tierra.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sistemasApantallamiento: file(
        relativePath: { eq: "electricidad/diseno-apantallamiento.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      subestacionesElectricas: file(
        relativePath: {
          eq: "electricidad/montaje-subestaciones-electricas.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tablerosElectricos: file(
        relativePath: { eq: "electricidad/instalacion-tableros-electricos.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      reparacionesElectricas: file(
        relativePath: { eq: "electricidad/reparaciones-electricas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tomacorrientesNormales: file(
        relativePath: { eq: "electricidad/toma-corrientes-normales.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tomasPolarizacion: file(
        relativePath: { eq: "electricidad/tomas-con-polarizacion.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tomasSeguridad: file(
        relativePath: { eq: "electricidad/tomas-de-seguridad.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      disenosPanelesSolares: file(
        relativePath: { eq: "electricidad/diseno-paneles-solares.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionPanelesSolares: file(
        relativePath: { eq: "electricidad/instalacion-paneles-solares.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasInteriores: file(
        relativePath: { eq: "electricidad/camaras-seguridad-interiores.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasExteriores: file(
        relativePath: { eq: "electricidad/camaras-seguridad-exteriores.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasRobotizadas: file(
        relativePath: { eq: "electricidad/camaras-robotizadas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasFijas: file(
        relativePath: { eq: "electricidad/camaras-fijas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasPanoramicas: file(
        relativePath: { eq: "electricidad/camaras-panoramicas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasDomo: file(relativePath: { eq: "electricidad/camaras-domo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cctv: file(relativePath: { eq: "electricidad/circuito-cerrado-tv.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      camarasIP: file(relativePath: { eq: "electricidad/camaras-ip.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionAlarmas: file(
        relativePath: { eq: "electricidad/instalacion-alarmas.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      montajeCavas: file(
        relativePath: { eq: "electricidad/montaje-de-cavas-refrigeracion.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      montajeAireVehicular: file(
        relativePath: { eq: "electricidad/montaje-aire-acondicionado.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      montajeAireResidencial: file(
        relativePath: { eq: "electricidad/instalacion-aire-acondicionado.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mantenimientoLavadoras: file(
        relativePath: {
          eq: "electricidad/mantenimiento-reparacion-neveras-lavadoras.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      parametrosElectricos: file(
        relativePath: { eq: "electricidad/medicion-parametros-electricos.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      estudiosIngenieria: file(
        relativePath: { eq: "electricidad/estidios-ingenieria.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  // : file(relativePath: { eq: "electricidad/.png" }) { childImageSharp { fluid(quality: 90, maxWidth: 400) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
}

export const ElectricianImage = ({ reference, ...props }) => {
  const data = useElectricianImages()

  return <Img fluid={data[reference].childImageSharp.fluid} {...props} />
}
