import * as React from 'react'

export interface IconButtonProps {
  label: string
  icon: React.ReactNode
  onClick: () => void
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, label, ...props }, ref) => {
    return (
      <button
        aria-label={label}
        className={'text-blue-500 border-blue-500 border p-2 rounded'}
        {...props}
      >
        {icon}
      </button>
    )
  },
)
