import * as React from 'react'

export interface FooterProps { }

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className={'text-center bg-gray-600 py-2.5'}>
      <p className={'text-white text-sm'}>
        2013 - {new Date().getFullYear()} | Desarrollado por{' '}
        <b>Domicilios 24/7 S.A.S.</b>
        <span style={{ color: 'red' }}>&#10084;</span> 🧠 🇨🇴
      </p>
    </footer>
  )
}
