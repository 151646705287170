import * as React from 'react'

export interface NavBarProps extends React.HTMLProps<HTMLButtonElement> {
  isOpen: boolean
}

export const NavBar = React.forwardRef<HTMLDivElement, NavBarProps>(
  ({ isOpen, ...props }, ref) => {
    return (
      <nav
        ref={ref}
        className={`m-0 overflow-hidden rounded-b-md bg-white z-15 absolute md:relative w-full md:w-auto left-0 md:left-auto top-full md:top-auto flex-col md:flex-row shadow-lg md:shadow-none ${
          isOpen ? 'flex' : 'hidden'
        } md:block`}
        {...props}
      />
    )
  },
)
