import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const usePlumberImages = () => {
  return useStaticQuery(graphql`
    query {
      destapesCanerias: file(
        relativePath: { eq: "plomeria/destape-canerias.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionDuchasElectricas: file(
        relativePath: { eq: "plomeria/instalacion-duchas.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionGriferia: file(
        relativePath: { eq: "plomeria/instalacion-griferia.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      instalacionCalentadores: file(
        relativePath: { eq: "plomeria/instalacion-calentadores-electricos.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      reparacionCalentadores: file(
        relativePath: { eq: "plomeria/reparacion-calentadores.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      reparacionEstufas: file(
        relativePath: { eq: "plomeria/reparacion-estufas.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      geofonoDigital: file(
        relativePath: { eq: "plomeria/reparacion-tuberias-geofono.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  // : file(relativePath: { eq: "plomeria/.jpg" }) { childImageSharp { fluid(quality: 90, maxWidth: 400) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
}

export const PlumberImage = ({ reference, ...props }) => {
  const data = usePlumberImages()

  return <Img fluid={data[reference].childImageSharp.fluid} {...props} />
}
