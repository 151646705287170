import * as React from 'react'
import { FaPhone, FaPhoneVolume } from 'react-icons/fa'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import config from './config'
import { useUserAction } from './client-identity'
import { LeftIcon } from './button-icon'

interface CallButtonProps {
  info?: 'icon' | 'button'
  action: string
  children?: React.ReactNode
}

export const CallButton = React.forwardRef<HTMLAnchorElement, CallButtonProps>(
  ({ action, children, info, ...props }, ref) => {
    const onClick = useCallButton({ action })
    const href = usePhoneAnchorReference()

    if (info === 'icon') {
      console.warn(
        'Deprecated use of CallButton as an icon button. Use the CallIconButon component instead',
      )

      return <CallIconButton action={action} />
    }

    return (
      <a
        ref={ref}
        href={href}
        onClick={onClick}
        className={'btn btn-blue'}
        {...props}
      >
        <LeftIcon>
          <FaPhone />
        </LeftIcon>{' '}
        {children} {config.contact.phoneNumbers[0]}
      </a>
    )
  },
)

export interface CallIconButtonProps
  extends React.HTMLProps<HTMLAnchorElement> {
  action: string
}

export const CallIconButton: React.FC<CallIconButtonProps> = ({
  action,
  ...props
}) => {
  const onClick = useCallButton({ action })
  const href = usePhoneAnchorReference()

  return (
    <a
      href={href}
      onClick={onClick}
      className={'text-blue-500 h-14'}
      children={<FaPhoneVolume className={'text-6xl mx-auto'} />}
      aria-label={`Llamar ${config.contact.phoneNumbers[0]}`}
      {...props}
    />
  )
}

interface CallButtonOptions {
  action: string
}

const useCallButton = ({ action }: CallButtonOptions) => {
  const [send] = useUserAction()

  return React.useCallback(() => {
    trackCustomEvent({
      category: 'button',
      action: 'click',
      label: `phone-call:${action}`,
    })

    send('Llamar')
  }, [send, action])
}

const usePhoneAnchorReference = () => {
  return React.useMemo(() => `tel:${config.contact.phoneNumbers[0]}`, [])
}
